// CI
$primaryColor: #757575;
$primaryColorBright: #f3f3f4;
$primaryColorWeak: #dcdcdc;
$secondaryColor: #99cc25;
$tertiaryColor: #0f618d;
$tertiaryColorBright: #267199;
$blue: #10618d;
$white: #fff;
body {
  background-color: $primaryColor !important;
  color: #000000 !important;

  .navbar-default {
    background: $primaryColor;
  }

  .agile-list li:hover {
    cursor: default !important;
    background: unset;
  }

  .nav-tabs {
    margin-top: -20px;
    border-bottom: 0px;
    background-color: #fff;
    width: 100%;
    .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
    }
    .nav-link.active {
      background-color: $primaryColor;
      color: #fff;
    }
  }
  .minimalize-styl-2 {
    margin: 20px 5px 5px 20px;
    border-radius: 4px !important;
  }
  .nav-header {
    background: $white;
    height: 70px;
    //padding: 2px;
  }
  .nav > li > a {
    color: $primaryColorWeak;
    font-size: 1.1em;
  }
  .nav > li.active {
    background: $primaryColor;
    border-color: $primaryColorBright;
    border-left: 3px solid $primaryColorWeak;
  }
  ul.nav-second-level {
    background: $primaryColor;
  }
  .navbar-default .nav > li > a:hover,
  .navbar-default .nav > li > a:focus {
    background-color: $primaryColor;
    //color: #DCDCDC;
  }

  .btn-secondary {
    background: $tertiaryColor;
    border-color: $tertiaryColor;
    &:hover,
    &:active,
    &:focus {
      background: $tertiaryColor !important;
      border-color: $tertiaryColor !important;
    }
  }

  .btn-primary {
    background: $primaryColor;
    border-radius: 0px;
    border-color: $primaryColor;
    &:hover,
    &:active,
    &:focus {
      background: $primaryColor !important;
      border-color: $primaryColor !important;
    }
    &:disabled {
      cursor: not-allowed;
      background: $primaryColor !important;
      border-color: $primaryColor !important;
    }
  }
  .label.label-primary {
    background: $primaryColor;
  }
  .label.label-info {
    background: $secondaryColor;
  }
  a {
    color: $primaryColor;
  }
  &.mini-navbar .nav-header {
    background-color: $white !important;

    .logo-element {
      background-image: url(./assets/logo.png);
      width: 60px;
      background-size: 60px;
      height: 60px;
      margin: 5px;
    }
  }
  .form-control:focus,
  .single-line:focus {
    border-color: $primaryColor;
  }
}

// Definitions
.logo-big {
  background-image: url(./assets/logo-big.png);
  width: 190px;
  height: 50px;
  background-size: 100%;
  margin-left: -10px;
  margin-top: -22px;
}

body.mini-navbar {
  .logo-big {
    display: none;
  }
}

.logo-login {
  text-align: center;
  margin-bottom: 60px;
  margin-top: 70px;

  & > div {
    background-image: url(./assets/logo-big-login.png);
    width: 325px;
    height: 70px;
    background-size: 100%;
    display: inline-block;
  }
}

.loginscreen.middle-box {
  width: 400px !important;
  .m-t {
    width: 300px;
    margin-left: 50px;
  }
}

.nav-label {
  margin-left: 5px;
}

.navbar {
  height: 70px;
  h1 {
    position: absolute;
    left: 76px;
    top: 20px;
    margin: 0;
    font-size: 1.8em;
  }
}

.ibox-map {
  margin: -15px -20px -20px -20px;
}

.table-small {
  font-size: 11px;
  .label {
    padding: 1px 6px;
    margin-right: 5px;
  }
}

.table-block {
  display: block;
  font-size: 12px !important;
  :after {
    content: '\A';
    white-space: pre;
  }
  textarea {
    height: 130px;
    font-size: 12px;
    resize: none;
  }
}

.analysen-table {
  font-size: 12px;
}

.iib-form {
  .form-group > label {
    font-weight: bold;
  }
  input,
  select {
    padding: 4px 6px;
  }
  input[type='checkbox'] {
    margin-right: 5px;
    font-size: 13px;
  }
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

/*Datepicker */
.react-datepicker {
  width: 328px;
}
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
}

.filter-table {
  label {
    font-size: 12px;
  }
}
input,
select {
  font-size: 12px !important;
}

.progress-bar {
  background-color: #9b0f10 !important;
}

.form-buttons {
  margin-top: 20px;
  margin-bottom: 0 !important;

  .btn {
    margin-right: 10px;
  }
}

.widget_custom {
  margin-bottom: 2.5rem;
  .ibox {
    height: 90%;
    margin-bottom: 0px !important;
  }
  .ibox-content {
    //background-color: $white;
    height: 100%;
  }
}

.project-preview-buttons {
  margin-top: 10px;

  .btn {
    margin-bottom: 5px;
    svg {
      margin-right: 5px;
    }
  }
}

.pull-right {
  float: right;
}

.table-details {
  td {
    border: 0 !important;
  }
  &:hover {
    background: none !important;
  }
}

.iib-analysis {
  table {
    td,
    th {
      border: none !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
}

.iib-tabnavigation {
  background: #fff;
  margin: -20px -25px 0;
  padding: 25px;
  padding-bottom: 0;
  margin-bottom: 25px;
  .nav-tabs {
    border: 0 !important;
  }

  .nav-tabs .nav-link {
    cursor: pointer;

    &.active {
      background: #f3f3f4;
      border-bottom: 0 !important;
    }
  }
}

.iib-graph-navigation {
  .nav-link {
    padding: 5px 10px;
    cursor: pointer;
  }
  .iib-graph-navigation-content {
    border: 1px solid #dee2e6;
    border-top: 0;
    padding: 5px;
  }
}

.row {
  .col-5-1 {
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.cursor {
  cursor: pointer;
}

.autocomplete-dropdown-container {
  > * {
    padding: 5px;
  }
}

.react-autosuggest__container {
  position: relative;
  display: inline;
  width: 100%;

  ul,
  li {
    list-style: none;
    padding: 5px;
    width: 100%;
  }
}
.react-autosuggest__input {
  width: 100%;
  font-weight: 300;
  border: 1px solid #eee;
  border-radius: 4px;
}
.react-autosuggest__input:focus {
  outline: none;
}
.react-autosuggest__suggestions-container {
  position: relative;
  top: -1px;
  width: 99%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 0;
  z-index: 2;
}
.react-autosuggest__suggestions-container--open {
  border: 1px solid #ddd;
  margin-bottom: 4px;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--focused {
  background-color: #ddd;
}
.react-tagsinput-tag {
  background-color: #eee !important;
  border: 1px solid #eee !important;
  color: #272727 !important;
}
.react-tagsinput--focused {
  border-color: #ccc !important;
}

.numberOfCases-icon {
  font-weight: bold;
  background: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 14px;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}

.numberOfCases-icon-gem {
  border: 2px solid red;
  color: red;
  padding-top: 3px;
}

.numberOfCases-icon-sb {
  border: 2px solid orange;
  color: orange;
  padding-top: 3px;
}

.inline-loading {
  display: block;
  text-align: center;
  padding: 40px;
}
